const projectSchema = (offer) => {
  const date = new Date(offer.attributes.Deadline);
  const monthNames = [
    "janvier",
    "février",
    "mars",
    "avril",
    "mai",
    "juin",
    "juillet",
    "août",
    "septembre",
    "octobre",
    "novembre",
    "décembre",
  ];

  const day = date.getDate();
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();

  const frenchDate = `${day} ${month} ${year}`;
  return {
    id: offer.id,
    status: offer.attributes.Status, // enable or disable
    impactImage: offer.attributes.Image.data.attributes.url,
    projectTitle: offer.attributes.Title,
    teaserTitle: offer.attributes.TeaserTitle,
    amount: offer.attributes.Amount,
    country: offer.attributes.Country,
    deadline: frenchDate,
    fundingType: offer.attributes.TypeOfFinancing,
    sector: offer.attributes.Secteur,
    category: offer.attributes.Category,
    language: offer.attributes.Language,
    content: offer.attributes?.Content,
    link: offer.attributes?.Link,
  };
};

// add to google calendar
// share button
export default projectSchema;

import qs from "qs";
import ProjectStatus from "../Context/Filters/ProjectStatus";
const ServiceRoutes = {
  opportunity: {
    getAllOpportunities: "api/opportunities?populate=*",
    filterOpportunities: (filter) => {
      const filters = {
        Status: {
          $eq: "true",
        },
        
      };

      if (filter.Status.length) {
        if (filter.Status !== ProjectStatus()[0]) {
          filters["Category"] = {
            $eq: filter.Status,
          };
        }
      }
      if (filter.Search.length) {
        filters["Title"] = {
          $containsi: filter.Search,
        };
      }
      if (filter.Langue.length) {
        filters["Language"] = {
          $eq: filter.Langue,
        };
      }
      if (filter.Pays.length) {
        filters["Country"] = {
          $in: filter.Pays.flat(1),
        };
      }
      if (filter.Secteurs.length) {
        filters["Secteur"] = {
          $contains: filter.Secteurs,
        };
      }
      if (filter.Asaisir) {
        filters["ASaisir"] = {
          $eq: "true",
        };
      }
      const query = qs.stringify(
        {
          filters,
          pagination: {
            page: filter.Page,
            pageSize: 10,
          },
        },

        {
          encodeValuesOnly: true, // prettify URL
        }
      );

      return `api/opportunities?${query}&populate=*`;
    },
  },
  ads: {
    getAllAds: "api/annoucements?populate=*",
  },
};

export default ServiceRoutes;

import { Stack } from "@mui/material";
import React from "react";
import { width } from "../Context/themes/size";

const SubmitOpportunity = () => {
  return (
    <Stack
      sx={{ width: width, margin: "30px auto 0 auto", }}
    >
      <iframe
        title="Formulaire de Soumission"
        height= "1000px"
        src="https://a44247db.sibforms.com/serve/MUIFAG6-itX3baxRUbrREgVy236EUTB4uEpLN9ur-jdUl4QRjQcWyeGPlbxxxSQ7jN9wtyv8SfHebuxfCXm3jGfn56l95E308hFLMUsSfQUCzQK2yjJ8bgtjEU67eN_Z955-lum8iGoPQbcb6fM8Z3Nmc9wQcDtPg8vPRZo0qAI8MB7Dhr5eJtolTU_bYcDEk9OLu8jR6_6gwFUs"
        frameBorder="0"
        scrolling="auto"
        allowFullScreen
        style={{
          display: "block",
          margin: "0",
        }}
      ></iframe>
    </Stack>
  );
};

export default SubmitOpportunity;

import { createSlice } from "@reduxjs/toolkit";

const NavbarSlice = createSlice({
  name: "navbar",
  initialState: { key: undefined },
  reducers: {
    AddNavbarState(state, action) {
      state.key = action.payload.key;
    },
  },
});

export const { AddNavbarState } = NavbarSlice.actions;
export const selectedNavbar = (state) => state.navbar;

export default NavbarSlice.reducer;

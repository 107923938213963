import { useTheme } from "@emotion/react";
import { Stack } from "@mui/material";
import React from "react";
import Projects from "../Containers/Projects";
import ProjectDetails from "./ProjectDetails";
import Ads from "../components/Ads/Ads";

const Home = () => {
  const { width } = useTheme();
  const homeStyle = {
    width: width,
    margin: "auto",
  };
  const [projectDetails, setProjectDetails] = React.useState({
    state: false,
    offer: null,
  });


  return (
    <>
      <Stack className="home" sx={homeStyle}>
        <Ads />
        <Projects setProjectDetails={setProjectDetails} />
      </Stack>
      <ProjectDetails
        projectDetails={projectDetails}
        setProjectDetails={setProjectDetails}
      />
    </>
  );
};

export default Home;

/* eslint-disable jsx-a11y/iframe-has-title */
import { Box } from "@mui/material";
import React from "react";
import { width } from "../../Context/themes/size";

const Newsletter = () => {
  return (
    <Box sx={{ width: width, height: "80vh" }}>
      <iframe
        width="100%"
        height="100%"
        src="https://a44247db.sibforms.com/serve/MUIFAGDptXvIEnEk6ZpLZ5peuJv5bSBBN-p9S-0e_YrQjPInbktIrbEaBc_bDEGJsj6YODNo4Sauiq2kwxHjDLsR55N3hUh7X5Lg2GH-QgVXFWTHoZ7yX3S6ohHtcPlAeNcJQ8TRCuUEByvDb7lAMIuwNCOMMjaQnRywvqjHzv2XKPzl4yOu6kCQ2yPUnXTq9X_sv18z-z3CrlEb"
        frameBorder="0"
        scrolling="auto"
        allowFullScreen
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          maxWidth: "100%",
        }}
      ></iframe>
    </Box>
  );
};

export default Newsletter;

import { useTheme } from "@emotion/react";
import { Box } from "@mui/system";
import React from "react";
// import LiveStats from "./components/LiveStats";
import Footer from "./Containers/Footer";
import Navbar from "./Containers/Navbar";
import Router from "./Router/Router";
import PoppuContext from "./features/Poppu/PoppuContext";

function App() {
  const theme = useTheme();

  React.useEffect(() => {
    
  })
  return (
    <Box sx={{ backgroundColor: theme.palette.secondary.dark }}>
      <Navbar />
      {/* <LiveStats /> */}
      <PoppuContext />
      <Router />
      <Footer />
    </Box>
  );
}

export default App;

import { Box, Tab, Tabs } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { addFilterRadio, enableAsaisir } from "../features/Filter/FilterSlice";
import { AddNavbarState } from "../features/Navbar/NavbarSlice";

const TabSelect = ({ items, TabWidth, hanbleChange, value }) => {
  const dispatch = useDispatch();
  const handleChange = (event, newValue) => {
    hanbleChange(newValue);
  };
  return (
    <Box sx={{ width: "100%"}}>
      <Tabs
        onChange={handleChange}
        value={value}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        aria-label="scrollable force tabs example"
        sx={{ width: "100%" }}
      >
        {items.map((item, key) => (
          <Tab
            label={item}
            key={key}
            sx={TabWidth}
            onClick={() => {
              if (key === 5) {
                dispatch(AddNavbarState({ key: "" }));
                dispatch(addFilterRadio({ key: "Status", value: "" }));
                dispatch(enableAsaisir({ status: true }));
              } else {
                dispatch(enableAsaisir({ status: false }));
                dispatch(AddNavbarState({ key: item.toLowerCase() }));
                dispatch(addFilterRadio({ key: "Status", value: item }));
              }
            }}
          />
        ))}
      </Tabs>
    </Box>
  );
};

export default TabSelect;

import { Button, Stack } from "@mui/material";
import logoOfficialLight from "../../assets/icons/logoOfficialLight.png";
import logoOfficial from "../../assets/icons/logoOrange.png";
import React from "react";

const Logo = ({ widthImg = 80, dark = false }) => {
  return (
    <Button sx={{ m: "0", p: "0" }} onClick={() => window.location.reload()}>
      <Stack sx={{ width: { xs: "110px", sm: "120px", md: "130px" } }}>
        <img
          src={dark ? logoOfficial : logoOfficialLight}
          alt="logo"
          style={{ width: `${widthImg}%` }}
        />
      </Stack>
    </Button>
  );
};

export default Logo;

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormRadio from "./FormRadio";
import {
  addFilter,
  deleteFilter,
} from "../features/Filter/FilterSlice";
import { useDispatch} from "react-redux";
import CountrySelect from "./CountrySelect";

const Filter = ({ Items }) => {
  return (
    <Box sx={{ width: "100%" }}>
      {Items.map((item, key) => {
        if (item.Type === "checkbox") {
          return (
            <React.Fragment key={item.Title}>
              <CheckBoxFilter Title={item.Title} Items={item.Items} Expanded />
              <Divider />
            </React.Fragment>
          );
        } else if (item.Type === "radio") {
          return (
            <React.Fragment key={item.Title}>
              <FormRadio Title={item.Title} Items={item.Items} />
              <Divider />
              {key === 0 && (
                <Typography sx={{ opacity: 0 }} fontSize={"0.4rem"}>
                  ;;
                </Typography>
              )}
            </React.Fragment>
          );
        } else {
          return (
            <React.Fragment key={key}>
              <CountrySelect />
              <Divider />
            </React.Fragment>
          );
        }
      })}
    </Box>
  );
};

const CheckBoxFilter = ({ Title, Items, Expanded, WithAccordion = false }) => {
  const dispatch = useDispatch();
  return (
    <Box sx={{ width: "100%", overflow: "hidden", margin: "0", padding: "0" }}>
      <Accordion sx={{ boxShadow: "hidden" }} defaultExpanded={Expanded}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography
            sx={{
              fontSize: "0.9em",
              fontStyle: "italic",
              fontWeight: "bold",
              textTransform: "capitalize",
            }}
          >
            {Title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ width: "100%", overflow: "hidden" }}>
            <FormGroup>
              {WithAccordion
                ? Items.map((item, index) => {
                    return (
                      <CheckBoxFilter
                        Title={item.Title}
                        Items={item.Items}
                        Expanded={false}
                        key={index}
                      />
                    );
                  })
                : Items.map((item, index) => (
                    <FormControlLabel
                      control={<Checkbox />}
                      label={typeof item === "string" ? item : item.name}
                      key={index}
                      onChange={(event) => {
                        if (event.target.checked) {
                          dispatch(
                            addFilter({ key: Title, value: event.target.value })
                          );
                        } else {
                          dispatch(
                            deleteFilter({
                              key: Title,
                              value: event.target.value,
                            })
                          );
                        }
                      }}
                      value={typeof item === "string" ? item : item.code}
                    />
                  ))}
            </FormGroup>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default Filter;

import { useTheme } from "@emotion/react";
import { Box } from "@mui/material";
import React from "react";
import ProjectDetailsLoan from "../components/ProjectDetails/ProjectDetailsLoan";
import ProjectDetailsProfile from "../components/ProjectDetails/ProjectDetailsProfile";

const ProjectDetailsContentContainer = ({ offer }) => {
  const { palette } = useTheme();
  const ProjectDetailsContentStyle = {
    width: "cacl(100% - 19px)",
    border: "1px solid",
    borderColor: palette.secondary.main,
    backgroundColor: palette.secondary.dark,
    padding: "15px",
  };

  return (
    <Box sx={ProjectDetailsContentStyle}>
      <ProjectDetailsProfile offer={offer} />
      <ProjectDetailsLoan offer={offer} />
    
    </Box>
  );
};

export default ProjectDetailsContentContainer;

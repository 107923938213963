import { useTheme } from "@emotion/react";
import { Stack, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Carousel from "react-material-ui-carousel";
import { useDispatch, useSelector } from "react-redux";
import { AddAllAds, selectedOffers } from "../../features/Offers/OffersSlice";
import SessionService from "../../Services/SessionService";
import AdsSkeleton from "./AdsSkeleton";
import AnnoucementSchema from "../../Context/Concept/AnnoucementSchema";
import Redirect from "../../Helpers/Redirect";

const Ads = () => {
  const theme = useTheme();
  const ResponsibleBreakpointStatus = useMediaQuery((theme) =>
    theme.breakpoints.down("md")
  );
  const [skeletonState, setSkeletionState] = React.useState(true);
  const [display, setDisplay] = React.useState(true);
  const dispatch = useDispatch();
  const ads = useSelector(selectedOffers).ads;
  const checkOffer = React.useCallback(() => {
    if (ads !== null) {
      setSkeletionState(false);
      setDisplay(false);
    }
  }, [ads]);

  React.useEffect(() => {
    if (ads === null) {
      setSkeletionState(true);
      SessionService.ads.GetAllAds().then((data) => {
        if (!data.data.length) setDisplay(false);
        dispatch(AddAllAds({ ads: data.data }));
        setSkeletionState(false);
      });
    } else {
      setSkeletionState(false);
    }
  }, [checkOffer, dispatch, ads]);

  return (
    <Stack
      justifyContent={"center"}
      alignItems={"center"}
      sx={{ width: theme.width, display: (display && ads?.length) ? "initial" : "none" }}
    >
      <Box
        sx={{
          width: { xs: "80%", sm: "70%", md: "70%" },
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "row",
          margin: "auto",
          oveflow: "hidden",
          marginTop: "calc(40px)",
          borderRadius: "10px",
        }}
      >
        <>
          <Carousel
            sx={{
              width: "100%",
              background: "#F8F8FB",
            }}
            swipe
            indicators
            interval={5000}
            duration={1000}
            autoPlay
          >
            {skeletonState ? (
              <AdsSkeleton />
            ) : (
              ads.map((item, index) => {
                return (
                  <Redirect
                    key={item}
                    link={AnnoucementSchema(item).link}
                    target
                  >
                    <Stack>
                      <img
                        src={
                          !ResponsibleBreakpointStatus
                            ? AnnoucementSchema(item).imagePc
                            : AnnoucementSchema(item).imageMobile
                        }
                        alt="annonce"
                        style={{
                          borderRadius: "10px",
                          boxShadow: theme.shadows[3],
                          cursor: "pointer",
                        }}
                      />
                    </Stack>
                  </Redirect>
                );
              })
            )}
          </Carousel>
        </>
      </Box>
    </Stack>
  );
};

export default Ads;

import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { width } from "../Context/themes/size";
import bacgroundImage from "../assets/imgs/about.jpg";

const About = () => {
  return (
    <>
      <Box
        sx={{
          height: "350px",
          width: "100vw",
          backgroundImage: `url(${bacgroundImage})`,
          backgroundPositionY: "top",
          backgroundPositionX: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        mb={5}
      ></Box>
      <Stack
        sx={{ width: { xs: width, md: "80vw" }, margin: "auto" }}
        spacing={4}
      >
        <Typography variant="h1" fontSize={"2.2rem"}>
          À PROPOS DE NOUS
        </Typography>
        <Stack spacing={2}>
          <Typography variant="h5" fontWeight={"500"}>
            Bienvenue sur Opportunités Jeunes, une initiative porteuse d'avenir
            mise en place par ‘’Alliance Jeunesse Côte d'Ivoire’’. Notre
            plateforme est née de la vision commune de créer un espace où les
            jeunes peuvent découvrir, explorer et saisir des opportunités
            éducatives, professionnelles et personnelles.
          </Typography>
          <Typography fontSize={"1.1rem"}>
            L’Alliance Jeunesse Côte d’Ivoire (AJCI) est une alliance
            d’associations d’entrepreneurs, d’investisseurs, de décideurs, de
            chercheurs, réunis autour de la question du développement et de
            l’autonomisation des jeunes en Côte d’Ivoire.
            <br />
            <br />
            Chez L’Alliance Jeunesse Côte d’Ivoire (AJCI), nous croyons que
            chaque jeune mérite d'avoir accès à des ressources et des
            opportunités qui peuvent façonner leur avenir. Notre mission est de
            connecter les jeunes aux programmes de formation, aux bourses, aux
            stages, aux événements et aux projets qui peuvent les aider à
            grandir et à réaliser leur potentiel.
            <br />
            <br />
            Fondée par des passionnés de l'éducation et de l'autonomisation des
            jeunes, notre plateforme est conçue pour être un guichet unique où
            les jeunes peuvent trouver des informations, des conseils et des
            opportunités pertinentes à chaque étape de leur parcours. Nous
            croyons fermement en l'importance de l'éducation continue et du
            développement personnel, et nous nous engageons à soutenir les
            jeunes dans leur quête d'excellence.
            <br />
            <br />
            Ensemble avec Alliance Jeunesse Côte d'Ivoire, nous nous efforçons
            de créer un impact positif et durable sur la jeunesse ivoirienne.
            Nous vous invitons à explorer ‘’Opportunités Jeunes’’, à vous
            impliquer et à contribuer à l'avenir prometteur de notre pays en
            donnant aux jeunes les clés du succès. Rejoignez-nous dans cette
            aventure passionnante et inspirez-vous à réaliser vos aspirations…
          </Typography>
        </Stack>
      </Stack>
      ;
    </>
  );
};

export default About;

import React, { useCallback } from "react";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  InputAdornment,
  TextField,
  useTheme,
} from "@mui/material";
import { addFilterRadio } from "../features/Filter/FilterSlice";
import { useDispatch } from "react-redux";

const Search = ({ color = "primary" }) => {
  const dispatch = useDispatch();
  const theme = useTheme().palette;

  const SendSearchContent = useCallback((event) => {
    if (event.key === "Enter") {
      dispatch(addFilterRadio({ key: "Search", value: event.target.value }));
    }
  }, [dispatch]);
  return (
    <Box sx={{ width: "100%" }}>  
      <TextField
        sx={{
          width: "100%",
          color: "inherit",
          background: theme.secondary.dark,
        }}
        onKeyUp={SendSearchContent}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default Search;

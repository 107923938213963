import React from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ReplyIcon from "@mui/icons-material/Reply";
import { useTheme } from "@emotion/react";

import { Box, Button, CardMedia, Chip, Stack, Typography } from "@mui/material";
import ShareBtn from "../ShareBtn";
import CreateModal from "../Modal/CreateModal";
import GenerateModalButton from "../Modal/GenerateModalButton";
import AdjustIcon from "@mui/icons-material/Adjust";
import countriesList from "../../Seeds/country";
import Redirect from "../../Helpers/Redirect";

const ProjectDetailsProfile = ({ offer }) => {
  const { palette } = useTheme();
  const ProjectDetailsProfileStyle = {
    width: "cacl(100% - 20px)",
    border: "1px solid",
    borderColor: palette.secondary.main,
    backgroundColor: palette.secondary.light,
    padding: "15px",
    borderRadius: "15px",
  };
  const country = countriesList.find((item) => item.cca2 === offer?.country)
    ?.translations?.fra?.common;
  return (
    <Box sx={ProjectDetailsProfileStyle}>
      <Box>
        <Typography
          sx={{
            fontSize: "1.5em",
            fontWeight: "bold",
            textTransform: "capitalize",
            textAlign: "center",
          }}
        >
          {offer?.projectTitle}
        </Typography>
        <Stack
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          {offer?.amount && (
            <Typography
              sx={{
                fontSize: "1.1rem",
                fontWeight: "bold",
                marginBottom: "50px",
                alignItems: "center",
              }}
              color={"primary"}
            >
              Valeur:{" "}
              <Typography color={"black"} component={"span"}>
                {offer?.amount}
              </Typography>
            </Typography>
          )}
        </Stack>
      </Box>

      <CardMedia
        component={"img"}
        image={offer?.impactImage}
        sx={{
          width: "90%",
          height: "auto",
          maxHeight: "400px",
          margin: "15px auto",
          borderRadius: "5px",
          objectFit:'contain'
        }}
      />

      <Stack
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Stack
          sx={{
            width: "95%",
            height: "100%",
            p: { xs: 0, md: "auto" },
            m: { xs: 0, md: "auto" },
          }}
        >
          <Stack spacing={3}>
            <Stack
              justifyContent="space-between"
              sx={{ width: "100%", flexDirection: { xs: "column", md: "row" } }}
              flexWrap="wrap"
            >
              <Stack
                sx={{
                  textAlign: { xs: "center", md: "auto" },
                  justifyContent: { xs: "center", md: "flex-start" },
                  alignItems: { xs: "center", md: "flex-start" },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1em",
                    fontWeight: "bold",
                    textTransform: "capitalize",
                  }}
                >
                  Alliance Jeunesse Côte d'Ivoire
                </Typography>
                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "500",
                    textTransform: "capitalize",
                  }}
                >
                  date limite:{" "}
                  <Typography
                    color="error"
                    component={"span"}
                    fontSize={"0.95rem"}
                  >
                    {offer?.deadline}
                  </Typography>
                </Typography>
                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "500",
                    textTransform: "capitalize",
                  }}
                >
                  catégorie:{" "}
                  <Typography
                    color="black"
                    component={"span"}
                    fontSize={"0.95rem"}
                  >
                    {offer?.category}
                  </Typography>
                </Typography>
                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "500",
                    textTransform: "capitalize",
                  }}
                >
                  langue:{" "}
                  <Typography
                    color="black"
                    component={"span"}
                    fontSize={"0.95rem"}
                  >
                    {offer?.language}
                  </Typography>
                </Typography>
              </Stack>
              <Box
                sx={{
                  padding: 0,
                  margin: 0,
                  pt: "15px",
                }}
              >
                <Stack
                  direction={"row"}
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: { xs: "center", md: "flex-end" },
                    alignItems: "center",
                    flexWrap: "wrap",
                    margin: 0,
                    padding: 0,
                    rowGap: "13px",
                  }}
                >
                  <Stack
                    spacing={1}
                    sx={{ alignItems: { xs: "center", md: "flex-end" } }}
                    width={"100%"}
                  >
                    <Redirect target link={offer?.link}>
                      <Button
                        variant="contained"
                        color="warning"
                        type="submit"
                        sx={{
                          borderRadius: "5px",
                          width: { xs: "90%", md: "auto" },
                          display: { xs: "none", md: "block" },
                        }}
                        size="large"
                      >
                        <Typography
                          color={"white"}
                          fontWeight="600"
                          fontSize={"1rem"}
                        >
                          postuler
                        </Typography>
                      </Button>
                    </Redirect>
                    <CreateModal
                      OpenButton={GenerateModalButton}
                      ModalContent={ShareBtn}
                      ContentProps={{ url: window.location.href }}
                    >
                      <Button
                        variant="outlined"
                        startIcon={<ReplyIcon />}
                        sx={{
                          borderRadius: "5px",
                        }}
                        size="small"
                      >
                        <Typography
                          component={"span"}
                          variant="button"
                          sx={{ width: "min-content" }}
                          color={"primary"}
                          fontSize={"0.65rem"}
                        >
                          Partager
                        </Typography>
                      </Button>
                    </CreateModal>
                  </Stack>
                </Stack>
              </Box>
            </Stack>

            <Stack
              direction="row"
              alignItems="center"
              spacing={0.5}
              flexWrap="wrap"
              rowGap="5px"
              sx={{
                width: "100%",
                justifyContent: { xs: "center", md: "flex-start" },
              }}
            >
              <Chip
                icon={<LocationOnIcon />}
                size="small"
                label={`Pays: ${country}`}
                variant="filled"
                color="primary"
              />
              <Chip
                icon={<AdjustIcon />}
                size="small"
                label={`Secteur: ${offer?.sector}`}
                variant="filled"
                color="primary"
              />
            </Stack>

            <Box sx={{ width: "100%" }}>
              <Typography
                sx={{
                  wordBreak: "keep-all",
                }}
              >
                {offer?.teaserTitle}
              </Typography>
            </Box>

            <Box
              sx={{
                width: { xs: "100%", md: "30%" },
                padding: 0,
                margin: 0,
                pt: "15px",
                display: { xs: "block", md: "none" },
              }}
            >
              <Stack
                direction={"row"}
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: { xs: "center", md: "flex-end" },
                  alignItems: "center",
                  flexWrap: "wrap",
                  margin: 0,
                  padding: 0,
                  rowGap: "13px",
                }}
              >
                <Stack
                  spacing={1}
                  sx={{ alignItems: { xs: "center", md: "flex-end" } }}
                  width={"100%"}
                >
                  <Button
                    variant="contained"
                    color="warning"
                    type="submit"
                    sx={{
                      borderRadius: "5px",
                      width: { xs: "90%", md: "auto" },
                    }}
                    size="large"
                  >
                    <Typography
                      color={"white"}
                      fontWeight="600"
                      fontSize={"1rem"}
                    >
                      postuler
                    </Typography>
                  </Button>
                </Stack>
              </Stack>
            </Box>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};
export default ProjectDetailsProfile;

const AnnoucementSchema = (offer) => {
  return {
    id: offer.id,
    status: offer.attributes.Status, // enable or disable
    imagePc: offer.attributes.ImagePc.data.attributes.url,
    imageMobile: offer.attributes.ImageMobile.data.attributes.url,
    link: offer.attributes?.Url,
  };
};

// add to google calendar
// share button
export default AnnoucementSchema;

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Stack,
  Typography,
} from "@mui/material";
import ReplyIcon from "@mui/icons-material/Reply";

import React from "react";
import InvestmentRule from "../Context/Concept/InvestmentRule";
import GenerateModalButton from "./Modal/GenerateModalButton";
import CreateModal from "./Modal/CreateModal";
import ShareBtn from "./ShareBtn";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AdjustIcon from "@mui/icons-material/Adjust";
import countriesList from "../Seeds/country";
import Redirect from "../Helpers/Redirect";

const ProjectCard = ({ setProjectDetails, offer }) => {
  const country = countriesList.find((item) => item.cca2 === offer.country)
    .translations.fra.common;
  const schema = offer;
  return (
    <Card
      sx={{
        width: "100%",
        maxWidth: { xs: "100%", sm: "80%", md: "100%" },
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        margin: "auto",
      }}
      variant={"elevation"}
    >
      <Stack
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Stack
          sx={{
            width: { xs: "100%", md: "40%" },
            height: "100%",
            margin: { xs: "0", md: "10px" },
          }}
        >
          <CardMedia
            component={"img"}
            image={schema.impactImage}
            sx={{
              width: "100%",
              height: "auto",
              maxHeight: "250px",
              objectFit: "cover",
            }}
          />
          <Box
            sx={{
              width: "90%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "30%",
              margin: "10px auto",
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: "1em",
                  fontWeight: "bold",
                  wordBreak: "keep-all",
                  textTransform: "capitalize",
                }}
                textAlign={"center"}
              >
                {schema.projectTitle}
              </Typography>
              {schema.amount && (
                <Typography
                  color={"primary"}
                  sx={{
                    fontSize: "1.1rem",
                    fontWeight: "bold",
                    textTransform: "capitalize",
                    mt: 1,
                    textAlign: "center",
                  }}
                >
                  Valeur:{" "}
                  <Typography color="black" component={"span"} fontWeight={500}>
                    {schema.amount}
                  </Typography>
                </Typography>
              )}
            </Box>
          </Box>
        </Stack>

        <CardContent
          sx={{
            width: { xs: "100%", md: "calc(60% - 5px)" },
            height: "100%",
            p: { xs: 0, md: "auto auto auto 5px" },
            m: { xs: 0, md: "auto" },
          }}
        >
          <Stack spacing={3}>
            <Stack
              justifyContent="space-between"
              sx={{ width: "100%", flexDirection: { xs: "column", md: "row" } }}
            >
              <Stack
                sx={{
                  width: { xs: "100%", md: "auto" },
                  textAlign: { xs: "center", md: "auto" },
                  justifyContent: { xs: "center", md: "flex-start" },
                  alignItems: { xs: "center", md: "flex-start" },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "500",
                    textTransform: "capitalize",
                  }}
                >
                  date limite:{" "}
                  <Typography
                    color="error"
                    component={"span"}
                    fontSize={"0.95rem"}
                  >
                    {schema.deadline}
                  </Typography>
                </Typography>
                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "500",
                    textTransform: "capitalize",
                    textAlign: "start",
                  }}
                >
                  catégorie:{" "}
                  <Typography
                    color="black"
                    component={"span"}
                    fontSize={"0.95rem"}
                  >
                    {schema.category}
                  </Typography>
                </Typography>
                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "500",
                    textTransform: "capitalize",
                    textAlign: "start",
                  }}
                >
                  langue:{" "}
                  <Typography
                    color="black"
                    component={"span"}
                    fontSize={"0.95rem"}
                  >
                    {schema.language}
                  </Typography>
                </Typography>
              </Stack>
              <CardActions
                sx={{
                  width: { xs: "100%", md: "auto" },
                  padding: 0,
                  margin: 0,
                  pt: "15px",
                }}
              >
                <Stack
                  direction={"row"}
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: { xs: "center", md: "flex-end" },
                    alignItems: "center",
                    flexWrap: "wrap",
                    margin: 0,
                    padding: 0,
                    rowGap: "13px",
                  }}
                >
                  <Stack
                    spacing={1}
                    sx={{ alignItems: { xs: "center", md: "flex-end" } }}
                    width={"100%"}
                  >
                    <Redirect target link={offer?.link}>
                      <Button
                        variant="contained"
                        color="warning"
                        type="submit"
                        sx={{
                          borderRadius: "5px",
                          width: { xs: "90%", md: "auto" },
                          display: { xs: "none", md: "block" },
                        }}
                        size="large"
                      >
                        <Typography
                          color={"white"}
                          fontWeight="600"
                          fontSize={"1rem"}
                        >
                          postuler
                        </Typography>
                      </Button>
                    </Redirect>
                    <CreateModal
                      OpenButton={GenerateModalButton}
                      ModalContent={ShareBtn}
                      ContentProps={{ url: offer?.link }}
                    >
                      <Button
                        variant="outlined"
                        startIcon={<ReplyIcon />}
                        sx={{
                          borderRadius: "5px",
                        }}
                        size="small"
                      >
                        <Typography
                          component={"span"}
                          variant="button"
                          sx={{ width: "min-content" }}
                          color={"primary"}
                          fontSize={"0.65rem"}
                        >
                          Partager
                        </Typography>
                      </Button>
                    </CreateModal>
                  </Stack>
                </Stack>
              </CardActions>
            </Stack>

            <Stack
              direction="row"
              alignItems="center"
              spacing={0.5}
              flexWrap="wrap"
              rowGap="5px"
              sx={{
                width: "100%",
                justifyContent: { xs: "center", md: "flex-start" },
              }}
            >
              <Chip
                icon={<LocationOnIcon />}
                size="small"
                label={`Pays: ${country}`}
                variant="filled"
                color="primary"
              />
              <Chip
                icon={<AdjustIcon />}
                size="small"
                label={`Secteur: ${schema.sector}`}
                variant="filled"
                color="primary"
              />
            </Stack>
            <Box
              sx={{ width: "98%", cursor: "pointer" }}
              onClick={() => setProjectDetails({ state: true, offer: schema })}
            >
              <Typography
                sx={{
                  wordBreak: "keep-all",
                }}
              >
                {schema.teaserTitle.substring(0, InvestmentRule.card.text)}
                ...
                <Typography
                  component="span"
                  color={"primary"}
                  sx={{
                    fontWeight: "bold",
                    marginLeft: "3px",
                    wordBreak: "keep-all",
                    textTransform: "uppercase",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }}
                  fontSize={"0.70rem"}
                >
                  en savoir plus
                </Typography>
              </Typography>
            </Box>

            <CardActions
              sx={{
                width: { xs: "100%", md: "30%" },
                padding: 0,
                margin: 0,
                pt: "15px",
                display: { xs: "block", md: "none" },
              }}
            >
              <Stack
                direction={"row"}
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: { xs: "center", md: "flex-end" },
                  alignItems: "center",
                  flexWrap: "wrap",
                  margin: 0,
                  padding: 0,
                  rowGap: "13px",
                }}
              >
                <Stack
                  spacing={1}
                  sx={{ alignItems: { xs: "center", md: "flex-end" } }}
                  width={"100%"}
                >
                  <Redirect target link={offer?.link}>
                    <Button
                      variant="contained"
                      color="warning"
                      type="submit"
                      sx={{
                        borderRadius: "5px",
                        width: { xs: "90%", md: "auto" },
                      }}
                      size="large"
                    >
                      <Typography
                        color={"white"}
                        fontWeight="600"
                        fontSize={"1rem"}
                      >
                        postuler
                      </Typography>
                    </Button>
                  </Redirect>
                </Stack>
              </Stack>
            </CardActions>
          </Stack>
        </CardContent>
      </Stack>
    </Card>
  );
};

export default ProjectCard;

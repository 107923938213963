import React from "react";
import {
  Box,
  Button,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { NavLink } from "react-router-dom";
import Redirect from "../../Helpers/Redirect";
import Logo from "./Logo";
import TiktokButton from "../Button/Tiktok/TiktokButton";
import { useDispatch, useSelector } from "react-redux";
import {
  AddNavbarState,
  selectedNavbar,
} from "../../features/Navbar/NavbarSlice";
import {
  addFilterRadio,
  enableAsaisir,
  selectFilter,
} from "../../features/Filter/FilterSlice";

const ResponsiveNavbarContent = ({ MenuList }) => {
  const [sidebarStatus, setSidebarStatus] = React.useState(false);
  const navbarAutoFocusStatus = useSelector(selectedNavbar);
  const [autoFocus, setAutoFocus] = React.useState();
  const dispatch = useDispatch();
  const filter = useSelector(selectFilter);
  React.useEffect(() => {
    setAutoFocus(navbarAutoFocusStatus.key);
  }, [navbarAutoFocusStatus]);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setSidebarStatus(false);
  };

  return (
    <Stack
      sx={{
        display: { xs: "flex", sm: "none" },
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        position: "relative",
      }}
      direction={"row"}
      spacing={3}
    >
      <IconButton color="inherit" onClick={() => setSidebarStatus(true)}>
        <MenuIcon fontSize="large" />
      </IconButton>
      <Drawer
        anchor={"left"}
        open={sidebarStatus}
        onClose={toggleDrawer("left", false)}
      >
        <Box
          sx={{
            width: "calc(100vw - 30px)",
            height: "100vh",
            padding: "10px 15px",
          }}
        >
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <IconButton onClick={toggleDrawer("left", false)}>
              <HighlightOffIcon fontSize="large" color="primary" />
            </IconButton>
            <Logo dark />
          </Stack>

          <Stack sx={{ marginTop: "5vh" }}>
            <List>
              {MenuList.map((item) => (
                <ListItemButton
                  key={item.name}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    width: "100%",
                    textDecoration:
                      autoFocus === item.name.toLowerCase() && "underline",
                  }}
                  onClick={(event) => {
                    if (filter.Asaisir) {
                      dispatch(enableAsaisir({ status: false }));
                    }
                    dispatch(AddNavbarState({ key: item.name.toLowerCase() }));
                    dispatch(
                      addFilterRadio({
                        key: "Status",
                        value: item.name.toUpperCase(),
                      })
                    );
                  }}
                >
                  <ListItemText onClick={toggleDrawer("left", false)}>
                    <NavLink to={item.link} style={{ textDecoration: "none" }}>
                      <Typography
                        fontWeight={500}
                        sx={{
                          textTransform: "uppercase",
                          "&:hover": {
                            textDecoration: "underline",
                          },
                          fontSize: "16px",
                        }}
                      >
                        {item.name}
                      </Typography>
                    </NavLink>
                  </ListItemText>
                </ListItemButton>
              ))}
            </List>
            <Button
              variant={"contained"}
              color="warning"
              onClick={(event) => {
                dispatch(enableAsaisir({ status: true }));
              }}
            >
              <Typography
                color={filter.Asaisir ? "white" : "black"}
                fontWeight={500}
                sx={{
                  textTransform: "uppercase",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                  fontSize: "16px",
                }}
              >
                A Saisir
              </Typography>
            </Button>
          </Stack>

          <Stack
            justifyContent={"flex-end"}
            alignItems={"center"}
            spacing={3}
            sx={{
              position: "absolute",
              bottom: "10%",
              left: "50%",
              transform: "translate(-50%,-50%)",
            }}
          >
            <Redirect
              link={
                "https://www.tiktok.com/@opportunitesjeunes.ci?is_from_webapp=1&sender_device=pc"
              }
              target
            >
              <Button
                variant="contained"
                sx={{ backgroundColor: "black", padding: "25px 60px" }}
              >
                <TiktokButton />
              </Button>
            </Redirect>
          </Stack>
        </Box>
      </Drawer>

      <Stack>
        <Logo />
      </Stack>
    </Stack>
  );
};

export default ResponsiveNavbarContent;

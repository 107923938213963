import { configureStore } from "@reduxjs/toolkit";
import FilterSlice from "../features/Filter/FilterSlice";
import PoppuSlice from "../features/Poppu/PoppuSlice";
import OffersSlice from "../features/Offers/OffersSlice";
import NavbarSlice from "../features/Navbar/NavbarSlice";

export const store = configureStore({
  reducer: {
    filter: FilterSlice,
    poppu: PoppuSlice,
    offers: OffersSlice,
    navbar: NavbarSlice,
  },
});

import React from "react";
import { Button, Typography, Stack, MenuItem, useTheme } from "@mui/material";
import Redirect from "../../Helpers/Redirect";
import Logo from "./Logo";
import { Link } from "react-router-dom";
import TiktokButton from "../Button/Tiktok/TiktokButton";
import { useDispatch, useSelector } from "react-redux";
import {
  AddNavbarState,
  selectedNavbar,
} from "../../features/Navbar/NavbarSlice";
import {
  addFilterRadio,
  enableAsaisir,
  selectFilter,
} from "../../features/Filter/FilterSlice";
import { HomeRouteLink } from "../../Router/Routes";

const DesktopNavbarContent = ({ menuList }) => {
  const navbarAutoFocusStatus = useSelector(selectedNavbar);
  const [autoFocus, setAutoFocus] = React.useState();
  const dispatch = useDispatch();
  const theme = useTheme();
  const filter = useSelector(selectFilter);

  React.useEffect(() => {
    setAutoFocus(navbarAutoFocusStatus.key);
  }, [navbarAutoFocusStatus]);
  return (
    <>
      <Stack
        sx={{
          display: { xs: "none", sm: "flex" },
          width: "max-content",
        }}
        justifyContent="flex-start"
        alignItems="center"
        columnGap="3rem"
        direction="row"
      >
        <Logo />
      </Stack>

      <Stack
        direction={"row"}
        sx={{ minWidth: "40%", display: { xs: "none", sm: "flex" } }}
        justifyContent="space-between"
        alignItems="center"
        flexWrap={"wrap"}
        columnGap={"10px"}
      >
        {menuList.map((item) => (
          <MenuItem
            key={item.name}
            sx={{
              m: 0,
              p: 0,
              borderColor: theme.palette.secondary.main,
              borderBottom:
                autoFocus === item.name.toLowerCase() ? "3px solid" : "none",
            }}
            onClick={(event) => {
              if (filter.Asaisir) {
                dispatch(enableAsaisir({ status: false }));
              }
              dispatch(AddNavbarState({ key: item.name.toLowerCase() }));
              dispatch(
                addFilterRadio({
                  key: "Status",
                  value: item.name.toUpperCase(),
                })
              );
            }}
          >
            <Link
              to={item.link}
              style={{
                textDecoration: "none",
                margin: "0",
              }}
            >
              <Typography
                fontWeight={500}
                color={"secondary"}
                sx={{
                  textTransform: "uppercase",
                  fontSize: "0.70rem !important",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
              >
                {item.name}
              </Typography>
            </Link>
          </MenuItem>
        ))}

        <Link to={HomeRouteLink()}>
          <Button
            variant="contained"
            color="warning"
            onClick={(event) => {
              dispatch(enableAsaisir({ status: true }));
            }}
          >
            <Typography
              fontWeight={500}
              color={"white"}
              sx={{
                textTransform: "uppercase",
                fontSize: "12px !important",
              }}
            >
              A Saisir
            </Typography>
          </Button>
        </Link>
      </Stack>

      <Stack
        direction={"row"}
        sx={{
          width: "max-content",
          display: { xs: "none", sm: "flex" },
          marginLeft: "20px",
        }}
        spacing={2}
        justifyContent="flex-end"
        alignItems="center"
      >
        <Stack
          direction={"row"}
          justifyContent={"space-around"}
          alignItems={"center"}
          spacing={3}
        >
          <Redirect link={"https://tiktok.com"} target>
            <Button
              variant="contained"
              sx={{ backgroundColor: "black", padding: "20px 55px" }}
            >
              <TiktokButton />
            </Button>
          </Redirect>
        </Stack>
      </Stack>
    </>
  );
};

export default DesktopNavbarContent;

import {
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import ProjectContent from "../components/HomeProject/ProjectContent";
import ProjectOnglet from "../components/HomeProject/ProjectOnglet";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Filter from "../components/Filter";
import ProjectFilterItems from "../Context/Filters/ProjectFilterItems";
import CloseIcon from "@mui/icons-material/Close";

const Projects = ({ setProjectDetails }) => {
  const [showFilter, setShowFilter] = React.useState(false);
  return (
    <>
      <Stack
        sx={{ width: "100%", display: { xs: "flex", md: "none" } }}
        justifyContent={"center"}
        alignItems={"flex-end"}
      >
        <Button
          variant="contained"
          startIcon={<FilterAltIcon />}
          onClick={() => setShowFilter(true)}
          size="small"
          sx={{
            width: "min-content",
            marginTop: "25px",
            marginBottom: "10px",
          }}
        >
          <Typography
            sx={{ fontSize: "1.2em", textTransform: "capitalize" }}
            color={"white"}
          >
            Filtres
          </Typography>
        </Button>
      </Stack>
      <Stack
        sx={{
          position: "fixed",
          height: "100vh",
          width: "100vw",
          m: "0",
          p: "0",
          top: "0",
          left: "0",
          background: "white",
          zIndex: "9999999999",
          overflowY: "scroll",
          transition:'transform 0.3s',
          transform: !showFilter ? `translateX(${window.innerWidth}px)` : `translateX(${0}px)`
        }}
      >
        <Box sx={{ paddingTop: "10px" }}>
          <Button
            disabled
            variant="standard"
            startIcon={<FilterAltIcon />}
            sx={{ width: "100%" }}
          >
            <Typography sx={{ fontSize: "1.2em" }}>Filtres</Typography>
          </Button>
          <Stack
            justifyContent={"flex-start"}
            alignItems={"flex-end"}
            direction={"row"}
            sx={{ position: "absolute", top: "0", left: "5px" }}
          >
            <IconButton onClick={() => setShowFilter(false)}>
              <CloseIcon fontSize="large" />
            </IconButton>
          </Stack>
          <Divider sx={{ width: "100%" }} light={false} variant="string" />
        </Box>
        <Box sx={{ padding: "20px 10px" }}>
          <Filter Items={ProjectFilterItems()} />
        </Box>
      </Stack>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "row",
          margin: "auto",
          oveflow: "hidden",
          marginTop: { xs: "0", md: "15px" },
        }}
      >
        <ProjectOnglet />
        <ProjectContent setProjectDetails={setProjectDetails} />
      </Box>
    </>
  );
};

export default Projects;

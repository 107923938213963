import { Stack } from "@mui/material";
import React from "react";

const TutoVideo = () => {
  return (
    <Stack>
      <iframe
        src="https://www.veed.io/embed/049a4626-3bd4-4616-8665-fcc208032608"
        frameBorder={0}
        title="F78EB7C2-2E56-4421-884F-ECBD54FBF6B7"
        allowFullScreen
        style={{
          width: "90vw",
          minHeight: "80vh",
        }}
      ></iframe>
    </Stack>
  );
};

export default TutoVideo;

import React from "react";
import { Route, Routes } from "react-router-dom";
import NotFound from "../Pages/NotFound";
import Home from "../Pages/Home";
import {
  AboutRouteLink,
  HomeRouteLink,
  NotFoundRouteLink,
  SubmitOpportunityRouteLink,
} from "./Routes";
import About from "../Pages/About";
import SubmitOpportunity from "../Pages/SubmitOpportunity";

const Router = () => {
  return (
    <Routes>
      <Route>
        <Route path={HomeRouteLink()} element={<Home />} />
        <Route path={AboutRouteLink()} element={<About />} />
        <Route
          path={SubmitOpportunityRouteLink()}
          element={<SubmitOpportunity />}
        />
        <Route path={NotFoundRouteLink()} element={<NotFound />} />
      </Route>
    </Routes>
  );
};

export default Router;

import { useTheme } from "@emotion/react";
import { Box, Typography } from "@mui/material";
import { MuiMarkdown, getOverrides } from "mui-markdown";
import React from "react";

const ProjectDetailsLoan = ({ offer }) => {
  const { palette } = useTheme();
  return (
    <Box
      sx={{
        width: "100%",
        border: "1px solid",
        borderColor: palette.secondary.main,
        backgroundColor: palette.secondary.light,
        padding: "15px",
        borderRadius: "15px",
        marginTop: "15px",
        display: "flex",
        justifyContent: "center",
        alignitems: "center",
      }}
      id="details"
    >
      <Box sx={{ width: "95%" }}>
        <MuiMarkdown
          overrides={{
            ...getOverrides({}), 
            p: {
              component: Typography,
              props: {
                style: { marginTop: "15px" },
              },
            },
          }}
        >
          {offer?.content}
        </MuiMarkdown>
      </Box>
    </Box>
  );
};

export default ProjectDetailsLoan;
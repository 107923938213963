import React from "react";
import {  Skeleton, Stack } from "@mui/material";

const AdsSkeleton = () => {
  return (
    <Stack
      sx={{
        width: "100%",
      }}
      justifyContent="center"
      alignItems={"center"}
      direction={"row"}
    >
      <Skeleton
        variant="rounded"
        sx={{
          width: { xs: "90%", sm: "100%" },
          height: { xs: "300px", md: "200px" },
        }}
      />
    </Stack>
  );
};

export default AdsSkeleton;

import { Box, Typography } from "@mui/material";
import "./Tiktok.css";
import React from "react";
import Redirect from "../../../Helpers/Redirect";

const TiktokButton = () => {
  return (
    <Redirect link="https://www.tiktok.com/@opportunitesjeunes.ci?is_from_webapp=1&sender_device=pc" target>
      <Box className="container">
        <Typography className="text blue" fontSize={"0.8rem"}>
          Notre TikTok
        </Typography>
        <Typography className="text red" fontSize={"0.8rem"}>
          Notre TikTok
        </Typography>
      </Box>
    </Redirect>
  );
};

export default TiktokButton;

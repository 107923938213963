const ProjectStatus = () => {
  return [
    "Tous",
    "BOURSES D'ÉTUDES",
    "SUBVENTIONS",
    "CONCOURS",
    "FORMATION & CONFÉRENCES",
    "A SAISIR",
  ];
};

export default ProjectStatus;

const ProjectFilterItems = () => {
  return [
    // {
    //   Title: "Expire",
    //   Items: ["7 jours", "3 mois", "6 mois", "1 an"],
    //   Type: "radio",
    // },
    {
      Title: "Langue",
      Items: ["Français", "Anglais ", "Français et Anglais", "Autre"],
      Type: "radio",
    },
    {
      Title: "Pays",
      Items: ["Français", "Anglais ", "Français et Anglais", "Autre"],

      Type: "customze",
    },
    {
      Title: "Secteurs",
      Items:[
        "Agriculture",
        "Arts",
        "Business Management",
        "Construction",
        "Design",
        "Droit",
        "Economie",
        "Éducation",
        "Études à l'étranger",
        "E-commerce",
        "Entrepreneuriat",
        "Finance",
        "Genre",
        "Humanitaire",
        "Journalisme",
        "Leadership",
        "Média",
        "Numérique",
        "Participation citoyenne",
        "Politique",
        "Santé",
        "Science",
        "Social",
        "Services",
        "Sports",
        "Environnement",
        "Toutes les disciplines",
        "Autres"
      ],
      Type: "checkbox",
    },
  ];
};

export default ProjectFilterItems;

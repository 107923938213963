import ApiService from "./ApiService";
import ServiceRoutes from "./ServiceRoutes";

const SessionService = {
  opportunity: {
    async GetAllOpportunities() {
      return ApiService(
        ServiceRoutes.opportunity.getAllOpportunities,
        "get",
        ""
      );
    },
    async FiltreOpportunities(filter) {
      return ApiService(
        ServiceRoutes.opportunity.filterOpportunities(filter),
        "get",
        ""
      );
    },
  },
  ads: {
    async GetAllAds() {
      return ApiService(ServiceRoutes.ads.getAllAds, "get", "");
    },
  },
};

export default SessionService;

import React from "react";
import {
  AppBar,
  Toolbar,
  Box,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import DesktopNavbarContent from "../components/Navbar/DesktopNavbarContent";
import ResponsiveNavbarContent from "../components/Navbar/ResponsiveNavbarContent";
import { width } from "../Context/themes/size";
import { AboutRouteLink } from "../Router/Routes";

export const menuList = [
  { name: "Bourses d'études", link: "/" },
  { name: "Subventions", link: "/" },
  { name: "Concours", link: "/" },
  { name: "Formation & Conférences", link: "/" },
  // { name: "Blog", link: "/" },
  { name: "A propos de nous", link: AboutRouteLink() },
];

const Navbar = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { palette } = useTheme();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box component={"header"} sx={{ overflow: "hidden" }}>
      <AppBar component={"nav"} position="static" color={"primary"}>
        <Toolbar
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "10vh",
            padding: "0 !important",
            position: "relative",
          }}
        >
          <Typography
            variant="body2"
            fontStyle={"italic"}
            color={"white"}
            textTransform={"capitalize"}
            sx={{
              position: "absolute",
              top: "0px",
              left: "5px",
              fontSize: { xs: "0.65rem", sm: "0.7rem" },
              fontWeight: { xs: "500", sm: "800" },
            }}
          >
            <Typography
              component={"span"}
              color={palette.warning.light}
              fontWeight={"500"}
              sx={{
                fontSize: { xs: "0.7rem", sm: "0.8rem" },
              }}
            >
              Une initiative de:{"  "}
            </Typography>
            l'Alliance jeunesse côte d'ivoire
          </Typography>
          <Stack
            direction="row"
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={{ width: width }}
          >
            <DesktopNavbarContent
              handleClick={handleClick}
              open={open}
              anchorEl={anchorEl}
              handleClose={handleClose}
              menuList={menuList}
            />

            <ResponsiveNavbarContent MenuList={menuList} />
          </Stack>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Navbar;

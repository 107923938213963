import { createSlice } from "@reduxjs/toolkit";

const OffersSlice = createSlice({
  name: "offers",
  initialState: { offers: null, ads: null, meta: null },
  reducers: {
    AddAllOffers(state, action) {
      state.offers = action.payload.offers;
    },
    AddAllAds(state, action) {
      state.ads = action.payload.ads;
    },
    AddAllMeta(state, action) {
      state.meta = action.payload.meta;
    },
  },
});

export const { AddAllOffers, AddAllAds, AddAllMeta } = OffersSlice.actions;
export const selectedOffers = (state) => state.offers;

export default OffersSlice.reducer;

import { useTheme } from "@emotion/react";
import { Box, Button, Divider, IconButton, Typography } from "@mui/material";
import React from "react";
import FacebookTwoToneIcon from "@mui/icons-material/FacebookTwoTone";
import CreateModal from "../components/Modal/CreateModal";
import Newsletter from "../components/Newsletter/Newsletter";
import { AboutRouteLink, SubmitOpportunityRouteLink } from "../Router/Routes";
import Redirect from "../Helpers/Redirect";
import TelegramIcon from "@mui/icons-material/Telegram";
import TutoVideo from "../components/TutoVideo";
const Footer = () => {
  const { palette, width } = useTheme();
  return (
    <Box
      sx={{
        minHeight: "60vh",
        width: width,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        margin: "10vh auto 0 auto",
        flexWrap: "wrap",
      }}
      component={'footer'}
    >
      <Box
        sx={{
          width: "100%",
          backgroundColor: palette.primary.main,
          color: palette.secondary.main,
          height: "100%",
          borderRadius: "10px",
          display: "flex",
          flexWrap: "wrap",

          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          padding: "10vh 0 0 0",
          rowGap: "50px",
        }}
      >
        <Box
          sx={{
            width: "90%",
            height: "90%",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",

            alignItems: "flex-start",
            flexDirection: "row",
            rowGap: "30px",
            columnGap: "20px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignitems: "center",
              flexWrap: "wrap",

              flexDirection: "column",
            }}
          >
            <FooterTitle Title={"PAGES"} />
            <Redirect link={AboutRouteLink()}>
              <FooterItem>A Propos de nous</FooterItem>
            </Redirect>
            <CreateModal ModalContent={Newsletter} closeButton>
              <FooterItem>Newsletter</FooterItem>
            </CreateModal>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignitems: "center",
              flexWrap: "wrap",

              flexDirection: "column",
            }}
          >
            <FooterTitle Title={"Contacts"} />
            <Redirect link={"tel:+225 0708064848"} target>
              <FooterItem>+225 0708064848</FooterItem>
            </Redirect>
            <Redirect link={"mailto:bonjour@opportunitesjeunes.ci"} target>
              <FooterItem small>bonjour@opportunitesjeunes.ci</FooterItem>
            </Redirect>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignitems: "center",
              flexWrap: "wrap",

              flexDirection: "column",
            }}
          >
            <Typography
              sx={{ fontWeight: "bold", fontSize: "1.3rem" }}
              color={"secondary"}
            >
              Alliance Jeunesse Côte d'Ivoire
            </Typography>
            <Redirect link={SubmitOpportunityRouteLink()} target>
              <Button
                fontSize="medium"
                color="secondary"
                variant="contained"
                sx={{ borderRadius: "5px" }}
                onClick={() => {
                  window.scroll({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
              >
                Soumettre une opportunité
              </Button>
            </Redirect>
            <CreateModal ModalContent={TutoVideo} closeButton>
              <Button
                fontSize="medium"
                color="warning"
                variant="contained"
                sx={{ borderRadius: "5px", mt: "5px" }}
                onClick={() => {
                  window.scroll({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
              >
                <Typography
                  color={"white"}
                  variant="button"
                  fontSize={"0.85rem"}
                  fontWeight={500}
                >
                  {" "}
                  TUTORIEL VIDÉO
                </Typography>
              </Button>
            </CreateModal>
          </Box>
        </Box>

        <Box sx={{ width: "90%" }}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                flexDirection: "row",
                columnGap: { xs: "auto", sm: "10px" },
                rowGrap: "10px",
                flexWrap: "wrap",
              }}
            >
              <Typography
                sx={{ fontWeight: 500, fontSize: "1rem" }}
                color={"secondary"}
              >
                Nous Suivre
              </Typography>
              <Redirect
                link={"https://www.facebook.com/profile.php?id=61550920773424"}
                target
              >
                <IconButton size="medium" color="secondary" variant="contained">
                  <FacebookTwoToneIcon fontSize="medium" />
                </IconButton>
              </Redirect>
              <Redirect
                link={
                  "https://www.tiktok.com/@opportunitesjeunes.ci?is_from_webapp=1&sender_device=pc"
                }
                target
              >
                <IconButton size="medium" color="secondary" variant="contained">
                  <TikTokIcon color={palette.secondary.main} />
                </IconButton>
              </Redirect>

              <Redirect link={"https://t.me/+J0sOs00YzR1iMWU8"} target>
                <IconButton size="medium" color="secondary" variant="contained">
                  <TelegramIcon color={palette.secondary.main} />
                </IconButton>
              </Redirect>

              <IconButton size="medium" color="secondary" variant="contained">
                {/* add tiktok logo here */}
              </IconButton>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
                columnGap: "10px",
              }}
            ></Box>
          </Box>
          <Divider color={palette.secondary.main} />
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{ fontSize: "0.8em", margin: "10px 0 10px 0" }}
              color={"secondary"}
            >
              © 2023- 2024 Alliance Jeunesse Côte d'Ivoire ®.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const FooterItem = ({ Item, children, small = false }) => {
  return (
    <Typography
      sx={{
        "&:hover": { textDecoration: "underline" },
        textTransform: small ? "lowercase" : "capitalize",
        cursor: "pointer",
      }}
      color={"secondary"}
    >
      {Item}
      {children}
    </Typography>
  );
};

const FooterTitle = ({ Title }) => {
  return (
    <>
      <Typography
        sx={{ fontWeight: "bold", fontSize: "1.3rem" }}
        color={"secondary"}
      >
        {Title}
      </Typography>
    </>
  );
};

const TikTokIcon = ({ color = "#ffff" }) => {
  return (
    <svg
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      width="24px"
    >
      <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />
    </svg>
  );
};
export default Footer;

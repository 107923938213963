import { createSlice } from "@reduxjs/toolkit";

const IntialiState = {
  Status: "",
  Search: "",
  Langue: "",
  Expire: [],
  Pays: [],
  Secteurs: [],
  Asaisir: false,
  Page: 1,
};

const FilterSlice = createSlice({
  name: "filter",
  initialState: IntialiState,
  reducers: {
    addFilter(state, action) {
      const value = action.payload.value;
      const key = action.payload.key;
      state.Page = 1;
      if (key === "Pays") {
        state.Pays = value;
      } else {
        state[key].push(value);
      }
    },
    addFilterRadio(state, action) {
      const value = action.payload.value;
      const key = action.payload.key;
      state.Page = 1;
      if (Object.keys(state).includes(key)) {
        state[key] = value;
      }
    },
    deleteFilter(state, action) {
      const value = action.payload.value;
      const key = action.payload.key;
      if (Object.keys(state.Pays).includes(key)) {
        const index = state.Pays[key].indexOf(value);
        if (index > -1) {
          state.Pays[key].splice(index, 1);
        }
      } else {
        const index = state.Secteurs.indexOf(value);
        if (index > -1) {
          state.Secteurs.splice(index, 1);
        }
      }
    },
    changePageFilter(state, action) {
      state.Page = action.payload.page;
    },
    enableAsaisir(state, action) {
      const status = action.payload.status;
      if (status) state.Status = "";
      state.Asaisir = status;
    },
  },
});

export const {
  addFilter,
  addFilterRadio,
  deleteFilter,
  changePageFilter,
  enableAsaisir,
} = FilterSlice.actions;

export const selectFilter = (state) => state.filter;

export default FilterSlice.reducer;

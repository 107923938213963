import {
  Box,
  IconButton,
  Pagination,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import ProjectCard from "../ProjectCard";
import Search from "../Search";
// eslint-disable-next-line no-unused-vars
import TabSelect from "../TabSelect";
import { useTheme } from "@emotion/react";
import ProjectStatus from "../../Context/Filters/ProjectStatus";
import SessionService from "../../Services/SessionService";
import ProjectSkeleton from "./ProjectSkeletion";
import { useDispatch, useSelector } from "react-redux";
import {
  AddAllMeta,
  AddAllOffers,
  selectedOffers,
} from "../../features/Offers/OffersSlice";
import projectSchema from "../../Context/Concept/ProjectSchema";
import {
  changePageFilter,
  selectFilter,
} from "../../features/Filter/FilterSlice";
import { selectedNavbar } from "../../features/Navbar/NavbarSlice";
import { menuList } from "../../Containers/Navbar";
import { DoNotDisturb } from "@mui/icons-material";

const ProjectContent = ({ setProjectDetails }) => {
  const { palette } = useTheme();
  const tabItems = ProjectStatus();
  const [value, setValue] = React.useState(0);
  const [skeletonState, setSkeletionState] = React.useState(true);
  const [filterReady, setFilterReady] = React.useState(0);
  const dispatch = useDispatch();
  const { meta, offers } = useSelector(selectedOffers);
  const filter = useSelector(selectFilter);
  const navbarAutoFocusStatus = useSelector(selectedNavbar);

  const hanbleChange = React.useCallback(
    (item) => {
      setValue(item);
    },
    [setValue]
  );

  React.useEffect(() => {
    menuList.forEach((item, key) => {
      if (
        item.name.toLowerCase() === navbarAutoFocusStatus.key &&
        !filter.Asaisir
      ) {
        setValue(key + 1);
      } else if (filter.Asaisir) {
        setValue(5);
      }
    });
  }, [dispatch, navbarAutoFocusStatus, filter]);

  const checkOffer = React.useCallback(() => {
    if (offers !== null) {
      setSkeletionState(false);
    }
  }, [offers]);

  React.useEffect(() => {
    if (offers === null) {
      setSkeletionState(true);
      SessionService.opportunity.FiltreOpportunities(filter).then((data) => {
        dispatch(AddAllOffers({ offers: data.data }));
        dispatch(AddAllMeta({ meta: data.meta }));
        setSkeletionState(false);
      });
    } else {
      setSkeletionState(false);
    }
  }, [checkOffer, dispatch, filter, offers]);

  React.useEffect(() => {
    setFilterReady((state) => (state += 1));
    if (filterReady >= 1) {
      setSkeletionState(true);
      SessionService.opportunity.FiltreOpportunities(filter).then((data) => {
        dispatch(AddAllOffers({ offers: data.data }));
        dispatch(AddAllMeta({ meta: data.meta }));
        setSkeletionState(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, filter]);

  return (
    <Stack
      sx={{
        width: { xs: "95vw", sm: "80%", md: '"70.5%"' },
        margin: "0 0 0 1%",
        backgroundColor: palette.secondary.light,
        border: "1px solid ",
        borderColor: palette.secondary.main,
        borderRadius: "10px",
      }}
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        spacing={3}
        justifyContent="center"
        alignItems="center"
        sx={{ width: "96%" }}
      >
        <TabSelect
          items={tabItems}
          TabWidth={{ width: "max-content" }}
          hanbleChange={hanbleChange}
          value={value}
        />

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <Search color="primary" />
        </Box>
        <Stack
          justifyContent={"space-between"}
          alignItems="center"
          direction="row"
          rowGap="40px"
          flexWrap={"wrap"}
          sx={{
            width: "100%",
          }}
        >
          {skeletonState === false ? (
            offers.length >= 1 ? (
              offers.map((item) => {
                if (
                  item.attributes.Status === true &&
                  new Date(item.attributes.Deadline).getTime() >=
                    new Date().getTime()
                ) {
                  return (
                    <ProjectCard
                      key={item.id}
                      setProjectDetails={setProjectDetails}
                      offer={projectSchema(item)}
                    />
                  );
                } else {
                  // eslint-disable-next-line array-callback-return
                  return;
                }
              })
            ) : (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  borderColor: palette.error.main,
                  borderStyle: "dotted",
                  borderWidth: "1px",
                  padding: "40px",
                }}
              >
                <IconButton>
                  <DoNotDisturb
                    sx={{ width: "100px", height: "100px" }}
                    color="error"
                  />
                </IconButton>
                <Typography textAlign={"center"} fontWeight={500}>
                  Nous sommes désolés, mais aucun résultat ne correspond à vos
                  filtres actuels. Veuillez réviser vos critères de recherche ou
                  essayer avec des filtres différents pour trouver ce que vous
                  cherchez.
                </Typography>
              </Box>
            )
          ) : (
            [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => (
              <ProjectSkeleton key={item} />
            ))
          )}
        </Stack>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {meta && (
            <Pagination
              count={meta.pagination.pageCount}
              onChange={(event, page) => {
                dispatch(changePageFilter({ page }));
              }}
              color="primary"
              sx={{ margin: "10px 0 30px 0" }}
            />
          )}
        </Box>
      </Stack>
    </Stack>
  );
};

export default ProjectContent;
